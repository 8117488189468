import React, { Component } from 'react';
import Highcharts from 'highcharts';
import HighChartsMore from 'highcharts/highcharts-more';

HighChartsMore(Highcharts);

export default class SpiderwebChart extends Component {

    componentDidMount = () => {

        Highcharts.chart(this.props.charId + 'char', {
            chart: {
                backgroundColor: null,
                plotBackgroundColor: null,
                plotBorderWidth: null,
                plotShadow: false,
                type: 'bar'
            },
            credits: {
                enabled: false
            },
            title: {
                text: undefined
            },
            exporting: {
                buttons: {
                    contextButton: {
                        enabled: false,
                    },
                },
            },
            xAxis: {
                type: 'category',
                labels: {
                    autoRotation: [-45, -90],
                    style: {
                        fontSize: '13px',
                        fontFamily: 'Verdana, sans-serif'
                    }
                }
            },
            yAxis: {
                min: 0,
                max: 90,
                title: {
                    text: undefined
                },
                labels: {
                    enabled: false
                }
            },
            legend: {
                enabled: false
            },
            tooltip: {
                pointFormat: '{point.y:.1f}'
            },
            series: [{
                name: 'Population',
                colors: [
                    '#9b20d9', '#9215ac', '#861ec9', '#7a17e6', '#7010f9', '#691af3',
                    '#6225ed', '#5b30e7', '#533be1', '#4c46db', '#4551d5', '#3e5ccf',
                    '#3667c9', '#2f72c3', '#277dbd', '#1f88b7', '#1693b1', '#0a9eaa',
                    '#03c69b', '#00f194'
                ],
                colorByPoint: true,
                groupPadding: 0,
                data: [
                    ['Master', this.props.stats.mastery.rating],
                    ['M Crit', this.props.stats.melee_crit.rating],
                    ['M Haste', this.props.stats.melee_haste.rating],
                    ['R Crit', this.props.stats.ranged_crit.rating],
                    ['R Haste', this.props.stats.ranged_haste.rating],
                    ['S Crit', this.props.stats.spell_crit.rating],
                    ['S Haste', this.props.stats.spell_haste.rating],
                    ['Vers', this.props.stats.versatility]
                ],
                dataLabels: {
                    enabled: true,
                    color: '#FFFFFF',
                    align: 'right',
                    format: '{point.y:.1f}', // one decimal
                    style: {
                        fontSize: '8px',
                        fontFamily: 'Verdana, sans-serif'
                    }
                }
            }]
        })
    }


    render() {
        return (
            <div id={this.props.charId + 'char'} style={{ height: '50px', width: '100px' }} />
        )
    }
}